module.exports = [{
      plugin: require('/Users/jeffwells/wells.bio/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/jeffwells/wells.bio/node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"prist"},
    },{
      plugin: require('/Users/jeffwells/wells.bio/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"YOUR_GOOGLE_ANALYTICS_TRACKING_ID","head":true},
    },{
      plugin: require('/Users/jeffwells/wells.bio/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
